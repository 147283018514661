import React, { useState, useEffect } from "react"
import Layout from "../components/__layout"
import SEO from "../components/seo"
import ThemeProvider from "../context/theme"
import Ranking from "../components/ranking"
import Hero from "../components/hero"
import Steps from "../components/steps"
import Slider from "../components/slider"
import DonationInfo from "../components/donation-info"
import DonationCounter from "../components/donation-counter"
import Thanks from "../components/thanks"
import Footer from "../components/footer"
import firebase from "gatsby-plugin-firebase"
import Helmet from "react-helmet"
import numeral from "numeral"

numeral.register("locale", "it", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
})

numeral.locale("it")

const IndexPage = () => {
  const [data, setData] = useState(false)
  const [filter, setFilter] = useState("donations_count")

  useEffect(() => {
    updateData()
  }, [])

  useEffect(() => {
    updateData()
  }, [filter])

  const updateData = () => {
    firebase
      .firestore()
      .collection("campaigns")
      .doc("46337086")
      .get()
      .then(doc => {
        if (doc.exists) {
          console.log(doc.data())
          setData(doc.data())
        }
      })
  }

  return (
    <ThemeProvider>
      <Layout>
        <Helmet>
          <title>Donation League - Il campionato della solidarietà</title>
          <meta
            property="og:image"
            content="https://donationleague.com/images/ogimage.jpg"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="628" />
        </Helmet>
        <SEO
          title="Donation League - Il campionato della solidarietà"
          image="test"
        />
        <Hero amount={data.current_amount} goal={data.goal_amount} />
        {/*<Slider />
        <Steps />*/}
        <Ranking data={data} filter={filter} setFilter={setFilter} />
        <DonationInfo />
        <DonationCounter
          amount={data.current_amount}
          goal={data.goal_amount}
          data={data}
        />
        <Thanks donations={data.donations} />
      </Layout>
      <Footer />
    </ThemeProvider>
  )
}

export default IndexPage
