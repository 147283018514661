import React from "react"
import { Box, Flex, Text, Image, Heading } from "rebass"
import ProgressBar from "../__layout/progress-bar"
import numeral from "numeral"

const DonationCounter = ({ data, amount, goal }) => {
  return (
    <Flex
      px={[2]}
      mt={[5, 6]}
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={{ textAlign: "center" }}>
        {/*<Text
          fontSize={["20px", "24px"]}
          sx={{
            maxWidth: "500px",
            margin: "0 auto",
          }}
          color="lightgray"
        >
          {numeral(data.donations_count).format("0,0")} tifosi delle squadre
          italiane ci hanno aiutato a raccogliere per la ricerca
        </Text>*/}
        <ProgressBar amount={amount} goal={goal} />
      </Box>
    </Flex>
  )
}

export default DonationCounter
