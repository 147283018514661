import React from "react"
import { Box, Flex, Text, Image, Heading } from "rebass"
import GoalKeeperPicture from "../../images/goalkeeper.svg"
import endPicture from "../../images/end.jpg"
import TeamSelect from "../__layout/team-select"
import ProgressBar from "../__layout/progress-bar"
import useModal from "../../hooks/modal"
import LayoutModal from "../__layout/modal"
import SiteDescription from "../site-description"
import CountDown from "../countdown"

const Hero = ({ amount, goal }) => {
  const [isModalOpen, setIsModalOpen, toggle] = useModal()
  return (
    <Flex
      mt={[4, 5]}
      px={[3, 3, 4]}
      justifyContent="center"
      alignItems="center"
      flexDirection={["column-reverse", "row"]}
    >
      <Box
        width={[1, 3 / 10, 4 / 10, 1 / 2]}
        px={[5, 0, 2, 0]}
        pt={[4, 0, 0, 0]}
        pr={[5, 0, 2, 5]}
      >
        <Image src={endPicture} />
      </Box>
      <Box width={[1, 7 / 10, 6 / 10, 1 / 2]} pl={[3, 4]}>
        <Heading
          textAlign={["center", "left"]}
          fontSize={[4, 5, 5, 6]}
          color="text"
          sx={{ lineHeight: 1.1 }}
        >
          Le tifoserie italiane insieme hanno fatto gol contro il Coronavirus!
        </Heading>
        <Text mt={[1, 2, 3]} color="lightgray" textAlign={["center", "left"]}>
          Si è concluso <b>IL PRIMO CAMPIONATO DELLA SOLIDARIETÀ</b>: la{" "}
          <b>DONATION LEAGUE</b>.
        </Text>
        <Text mt={[1, 2, 3]} color="lightgray" textAlign={["center", "left"]}>
          Questa raccolta fondi è stata destinata{" "}
          <b>ALLA RICERCA DELLA CURA E DEL VACCINO</b> per combattere il
          coronavirus. Le tifoserie più generose nella classifica delle
          donazioni di questo primo campionato solidale sono state:
        </Text>
        <Text mt={[1, 2, 3]} color="lightgray" textAlign={["center", "left"]}>
          N.1 - tifoseria INTER
          <br />
          N.2 - tifoseria MILAN
          <br />
          N.3 - tifoseria JUVENTUS
        </Text>
        <Text mt={[1, 2, 3]} color="lightgray" textAlign={["center", "left"]}>
          <b>
            Il primo scudetto della solidarietà è stato vinto dai tifosi
            dell'INTER 🏆
          </b>
        </Text>
        <Text
          textAlign={["center", "left"]}
          my={4}
          sx={{ cursor: "pointer" }}
          fontSize={[1, 2]}
          color="primary"
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          Cos'è la Donation League?
        </Text>
        <ProgressBar amount={amount} goal={goal} />
        {/*
        <TeamSelect mt={2} />*/}
      </Box>
      <LayoutModal width={900} isOpen={isModalOpen} toggle={toggle}>
        <Flex justifyContent="center" sx={{ borderRadius: 8 }}>
          <SiteDescription />
        </Flex>
      </LayoutModal>
    </Flex>
  )
}

export default Hero
