import React from "react"
import _ from "lodash"
import Skeleton from "react-loading-skeleton"
import teams from "../../data/teams"
import { Box, Flex, Heading } from "rebass"
import numeral from "numeral"
import { FaTrophy, FaMoneyBillWave, FaSquareRootAlt } from "react-icons/fa"
import TeamSelect from "../__layout/team-select"

const LoadingLine = ({ count }) => {
  return _.times(count, i => {
    return (
      <Flex key={i} alignItems="center" justifyContent="center">
        <Box
          px={[3, 4]}
          py={[2, 3]}
          width={[0 / 10, 1 / 10]}
          display={["none", "block"]}
        >
          <Skeleton />
        </Box>
        <Box px={[3, 4]} py={[2, 2]} width={[6 / 10, 6 / 10]}>
          <Flex alignItems="center">
            <Box>
              <Flex alignItems="center">
                <Box style={{ marginRight: "10px" }}>
                  <Skeleton width="30px" height="30px" />
                </Box>
                <Box>
                  <Skeleton width="80px" />
                </Box>
              </Flex>
            </Box>
            <Box>
              <Skeleton />
            </Box>
          </Flex>
        </Box>
        <Box width={[4 / 10, 3 / 10]} px={[3, 4]} py={[2, 2]}>
          <Skeleton />
        </Box>
      </Flex>
    )
  })
}

const Ranking = ({ data, filter, setFilter }) => {
  let rank = 1
  return (
    <Box id="ranking" px={[3, 3, 4]} my={[4, 5]}>
      {/*<Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        mb={[4, 5]}
      >
        <TeamSelect mt={2} hideRankingLink={true} />
      </Flex>*/}
      <Heading
        fontSize={[3, 4, 5]}
        textAlign={["center", "left"]}
        lineHeight={[1, 1.2]}
        color="text"
        mb={4}
      >
        Classifiche finali
      </Heading>
      <Flex py={[2, 0]} flexDirection={["column", "column", "row"]}>
        <Box
          px={[2, 2]}
          py={[0, 0, 2]}
          color={filter === "donations_count" ? "primary" : "lightgray"}
          sx={{
            fontWeight: filter === "donations_count" ? "bold" : "normal",
            cursor: "pointer",
          }}
          onClick={() => {
            setFilter("donations_count")
          }}
        >
          <Flex alignItems="center">
            <Flex marginRight="5px">
              <FaTrophy />
            </Flex>
            <Box>Classifica generale</Box>
          </Flex>
        </Box>
        <Box
          px={[2, 2]}
          py={[0, 0, 2]}
          color={filter === "amount_raised" ? "primary" : "lightgray"}
          sx={{
            fontWeight: filter === "amount_raised" ? "bold" : "normal",
            cursor: "pointer",
          }}
          onClick={() => {
            setFilter("amount_raised")
          }}
        >
          <Flex alignItems="center">
            <Flex marginRight="5px">
              <FaMoneyBillWave />
            </Flex>
            <Box>Classifica valore donazioni</Box>
          </Flex>
        </Box>
        <Box
          px={[2, 2]}
          py={[0, 0, 2]}
          color={filter === "donation_avg" ? "primary" : "lightgray"}
          sx={{
            fontWeight: filter === "donation_avg" ? "bold" : "normal",
            cursor: "pointer",
          }}
          onClick={() => {
            setFilter("donation_avg")
          }}
        >
          <Flex alignItems="center">
            <Flex marginRight="5px">
              <FaSquareRootAlt />
            </Flex>
            <Box>Classifica media donazioni</Box>
          </Flex>
        </Box>
      </Flex>
      <Flex
        backgroundColor="primary"
        color="#ffffff"
        alignItems="center"
        justifyContent="center"
        fontWeight="bold"
        sx={{ borderRadius: 8 }}
        my={[0, 1]}
        mb={2}
      >
        <Box
          px={[3, 4]}
          py={[2, 3]}
          width={[0 / 10, 1 / 10]}
          display={["none", "block"]}
        >
          Pos.
        </Box>
        <Box px={[3, 4]} py={[2, 3]} width={[6 / 10, 6 / 10]}>
          Nome della Squadra
        </Box>
        <Box
          px={[3, 4]}
          py={[2, 3]}
          width={[4 / 10, 3 / 10]}
          sx={{ textAlign: "right" }}
        >
          {filter === "donations_count"
            ? "Punti totali"
            : filter === "amount_raised"
            ? "Valore donazioni"
            : "Media donazioni"}
        </Box>
      </Flex>
      <Box>
        {data ? (
          _.orderBy(data.teams, [filter, "last_name"], ["desc", "asc"]).map(
            (item, key) => {
              const team = _.find(teams, function(o) {
                return o.id === item.id
              })

              return team ? (
                <Flex
                  key={key}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ borderRadius: 6 }}
                  backgroundColor={key % 2 === 0 ? "#ffffff" : "#fafafa"}
                >
                  <Box
                    px={[3, 4]}
                    py={[2, 3]}
                    width={[0 / 10, 1 / 10]}
                    display={["none", "block"]}
                    fontWeight="bold"
                  >
                    #{rank++}
                  </Box>
                  <Box px={[3, 4]} py={[2, 2]} width={[6 / 10, 6 / 10]}>
                    <Flex alignItems="center">
                      <Box>
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            backgroundImage: `url(/images/teams/${team.logo})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            marginRight: "10px",
                          }}
                        ></div>
                      </Box>
                      <Box fontWeight="bold">{team.name}</Box>
                    </Flex>
                  </Box>
                  <Box
                    width={[4 / 10, 3 / 10]}
                    px={[3, 4]}
                    py={[2, 2]}
                    sx={{ textAlign: "right" }}
                  >
                    {filter === "donations_count"
                      ? item.donations_count + " punti"
                      : filter === "amount_raised"
                      ? numeral(item.amount_raised).format("0,0") + "€"
                      : numeral(item.donation_avg).format("0,0") + "€"}
                  </Box>
                </Flex>
              ) : null
            }
          )
        ) : (
          <LoadingLine count={20} />
        )}
      </Box>
      {/*
      <Box>
        <Text color="lightgray" textAlign="center">
          La classifica si aggiorna ogni 5 minuti
        </Text>
      </Box>
      */}
    </Box>
  )
}

export default Ranking
