import React from "react"
import { ThemeProvider } from "theme-ui"
import preset from "@rebass/preset"

export default props => {
  let theme = {
    ...preset,
  }

  theme.colors = {
    ...theme.colors,
    background: "white",
    primary: "#407BFF",
    lightgray: "#747d92",
    text: "#35393d",
    secondary: "#141623",
    midnight: "#272934",
    shadowBlue: "#7a859c",
    danger: "#e00000",
    whatsapp: "#25d366",
    facebook: "#4267b2",
    messenger: "#006aff",
    spotify: "#1db954",
    gold: "#fcb941",
    silver: "#bdc3c7",
    bronze: "#bf7731",
  }

  theme.fonts = {
    body: '"Lato", sans-serif',
    heading: '"Lato", sans-serif',
    monospace: "Menlo, monospace",
  }

  theme.radii = {
    default: 8,
    circle: 99999,
  }

  theme.variants.sliderItem = {
    position: "relative",
    cursor: "pointer",
    top: 0,
    link: {
      ...theme.variants.nav,
      p: 0,
      display: "block",
    },
    playButton: {
      position: "absolute",
      width: ["60px", "80px"],
      height: ["60px", "80px"],
      opacity: 0.4,
      marginTop: ["-30px", "-40px"],
      marginLeft: ["-30px", "-40px"],
      top: "50%",
      left: "50%",
    },
    imageContainer: {
      alignItems: "flex-end",
      height: ["40vh", "350px"],
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderRadius: 4,
    },
    gradientContainer: {
      p: [3],
      pt: [5],
      borderRadius: 8,
      flexGrow: 1,
      background: "linear-gradient(transparent, #263238)",
      "> h2": {
        fontSize: [2, 3],
        color: "white",
      },
      "> div, > div span": {
        color: "white",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
  }

  const defaultButtonStyle = {
    borderRadius: "circle",
    fontWeight: 400,
    cursor: "pointer",
    outline: "none",
    boxShadow: "0 3px 6px 0 rgba(0,0,0,.35)",
  }

  theme.variants.link = {
    ...theme.variants.link,
    textDecoration: "none",
  }

  theme.buttons = {
    ...theme.buttons,

    primary: {
      ...theme.buttons.primary,
      ...defaultButtonStyle,
      color: "white",
    },
    halfLeft: {
      ...theme.buttons.primary,
      ...defaultButtonStyle,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    halfRight: {
      ...theme.buttons.primary,
      ...defaultButtonStyle,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    outline: {
      ...theme.buttons.outline,
      ...defaultButtonStyle,
    },
    secondary: {
      ...theme.buttons.secondary,
      ...defaultButtonStyle,
    },
  }

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
