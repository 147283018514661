import React from "react"
import { Box, Flex, Text, Image, Heading } from "rebass"
import DonationPackagePicture from "../../images/donation_package.svg"

const DonationInfo = () => {
  return (
    <Flex
      px={[3, 3, 4]}
      mt={[4, 5]}
      id="donation-info"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width={[1, 1 / 2]}
        pr={[5, 4, 5]}
        pb={[4, 0, 0]}
        pl={[5, 0, 0]}
        pt={[4, 0, 0]}
      >
        <Image src={DonationPackagePicture}></Image>
      </Box>
      <Box width={[1, 1 / 2]} pl={[3, 0]}>
        <Heading
          fontSize={[3, 4, 5]}
          textAlign={["center", "left"]}
          lineHeight={[1, 1.2]}
          color="text"
        >
          Siamo scesi in campo per la ricerca della cura e del vaccino per
          sconfiggere il coronavirus.
        </Heading>
        <Text mt={[1, 2, 3]} color="lightgray" textAlign={["center", "left"]}>
          In questa fase delicata dal punto di vista sociale e sanitario, medici
          e ricercatori stanno facendo un lavoro importantissimo. Aiutiamo con
          le nostre donazioni lo sviluppo dei nuovi farmaci, la ricerca della
          cura e del vaccino per sconfiggere il COVID-19. Guarda il video qui
          sotto.
        </Text>
      </Box>
      <Box
        width={[1]}
        mt={[4, 5]}
        sx={{
          height: 0,
          paddingBottom: 900 / 16 + "%",
          position: "relative",
          overflow: "hidden",
          "& > iframe": {
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            bottom: 0,
            left: 0,
            border: 0,
          },
        }}
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/aKmEnuxuMy0?rel=0"
          frameBorder="0"
          allowFullScreen
        />
      </Box>
    </Flex>
  )
}

export default DonationInfo
