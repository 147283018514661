import React from "react"
import { Box, Flex, Text, Image, Heading, Link } from "rebass"
import logoWhite from "../../images/logo-w.png"
import rewaveLogo from "../../images/rw.svg"
import impactOnLogo from "../../images/impacton.png"

const Footer = () => {
  return (
    <Flex
      flexDirection="column"
      marginTop={["-16px", "-32px"]}
      bg="primary"
      color="#ffffff"
      fontSize={[1]}
      py={[3]}
      px={[5]}
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={["column", "column", "column", "row"]}
      >
        <Box>
          <Flex flexDirection={["column", "row"]} alignItems="center">
            <Box flexWrap="nowrap">
              <Image width="80px" marginRight={["0", "20px"]} src={logoWhite} />
            </Box>
            <Box>
              <Flex
                flexDirection={["column", "row"]}
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  textAlign={["center", "right", "left"]}
                  marginRight={["0", "20px"]}
                  fontSize={[1]}
                >
                  Iniziativa no profit di Claudio Cecchetto in collaborazione
                  con Rewave, ImpactOn
                </Text>
                <Flex mt={[2, 0]} justifyContent={["center"]}>
                  <Flex>
                    <Image
                      width="40px"
                      height="18px"
                      marginRight={["20px"]}
                      src={rewaveLogo}
                    />
                  </Flex>
                  <Flex>
                    <Image width="80px" height="18px" src={impactOnLogo} />
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box flex={[1]} pt={[3, 0]}>
          <Flex justifyContent={["center", "flex-end"]}>
            <Box>
              <Link
                color="#ffffff"
                href="https://www.iubenda.com/privacy-policy/98181881/cookie-policy"
                target="_blank"
              >
                Cookie Policy
              </Link>
            </Box>
            <Box>
              <Link
                color="#ffffff"
                paddingLeft="10px"
                href="mailto:info@donationleague.com"
              >
                Contattaci
              </Link>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Box textAlign="center" fontSize={0} pt={[3]}>
        Tutti i marchi, i loghi, i nomi citati sono di proprietà dei rispettivi
        titolari e sono stati utilizzati a puro scopo esplicativo, senza alcun
        fine di violazione dei diritti di copyright vigenti.
      </Box>
    </Flex>
  )
}

export default Footer
