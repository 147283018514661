import React from "react"
import { Flex, Heading, Box, Text } from "rebass"
import numeral from "numeral"
import Skeleton from "react-loading-skeleton"

const ProgressBar = ({ amount = 0, goal = 0, my = [4] }) => {
  const percentage = numeral((amount / goal) * 100).format("0,0")

  return (
    <Flex
      flexDirection="column"
      my={my}
      justifyContent={["center", "flex-start"]}
      alignItems={["center", "flex-start"]}
    >
      <Flex
        alignItems={["center", "flex-end"]}
        flexDirection={["column", "row"]}
        justifyContent={["center", "flex-start"]}
        alignItems={["center", "flex-end"]}
      >
        <Heading fontSize={[4, 5]} mr={[2]} sx={{ lineHeight: 1 }}>
          {amount ? (
            numeral(amount).format("0,0") + "€"
          ) : (
            <Skeleton width="80px" />
          )}
        </Heading>
        <Text fontSize={[0, 1]} color="lightgray">
          {goal ? (
            "raccolti su un obiettivo di " + numeral(goal).format("0,0") + "€"
          ) : (
            <Skeleton width="200px" />
          )}
        </Text>
      </Flex>
      <Flex
        sx={{
          width: "100%",
          backgroundColor: "#ccf1e0",
          height: 5,
          my: [1, 2],
        }}
        maxWidth={["200px", "100%"]}
      >
        <Box
          sx={{
            width: `${percentage}%`,
            backgroundColor: "#00b964",
            height: "100%",
          }}
        ></Box>
      </Flex>
    </Flex>
  )
}

export default ProgressBar
