import React from "react"
import { Box, Flex, Text, Image, Heading } from "rebass"
import thanksSvg from "../../images/thanks.svg"
import numeral from "numeral"
import { css, keyframes } from "@emotion/core"
const bounce = keyframes`
  0% {
    transform: translate(0px, 5px);
  }

  50% {
    transform: translate(0px, 20px);
  }

  100% {
    transform: translate(0px, 5px);
  }
`

const Thanks = ({ donations }) => {
  return (
    <Flex
      px={[2]}
      mt={[4, 5]}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
        {donations
          ? donations.slice(0, 4).map((donation, key) => (
              <Flex
                key={key}
                width={[1, 1 / 2]}
                justifyContent="center"
                mt={[4, 5]}
              >
                <Box
                  padding={[3]}
                  sx={{
                    borderRadius: 8,
                    textAlign: "left",
                    boxShadow: "0 0 10px 2px rgba(0, 0, 0, 0.1)",
                    zIndex: 10,
                  }}
                  css={css`
                    animation: ${bounce} 5s ease-in-out infinite;
                    animation-delay: ${key * 0.8}s;
                  `}
                  backgroundColor="#ffffff"
                >
                  <Heading fontSize={[2]}>
                    {donation.is_anonymous ? "Anonimo" : donation.name}
                  </Heading>
                  <Text color="lightgray">
                    ha appena donato {numeral(donation.amount).format("0,0")}€
                  </Text>
                </Box>
              </Flex>
            ))
          : "niente"}
      </Flex>
      <Flex>
        <Image
          src={thanksSvg}
          mt={["-80px"]}
          height={["300px", "450px"]}
        ></Image>
      </Flex>
    </Flex>
  )
}

export default Thanks
